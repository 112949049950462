import React, { useState, useCallback, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, OverlayView, OverlayViewF } from "@react-google-maps/api";
import { GetUserPosts } from "../network/GetPosts";
import SelectCities from "./SelectCities";
import './Map.css'; 
import MediaModal from "./MediaModal";
import { serverIp } from "../network/ConfigNetwork";

// Add your Google Maps API key here
const API_KEY = 'AIzaSyCtBzS4JJIgt8sHmPrmovOjzHgqMRpqW0g';

//url: "http://127.0.0.1:8080/images/2592_lowqual.webp"

// Map container style (required for GoogleMap)
const mapContainerStyle = {
  width: "100wh",
  height: "100vh",
};


const getInitCoordinates = (posts) => {
  var group1 = 0   // longitude between -140 and -25 (America)
  var group2 = 0   // longitude between -25 and 55 (Europe/Africa)
  var group3 = 0   // longitude between 55 and 140 (Asia)
  var cities = []

  if (posts && posts.length>0){
      posts.forEach(e => {
          if (cities.indexOf(e.city) == -1){
              if (e.longitude < -25){
                  group1 = group1 + 1
              }
              else if (e.longitude > -25 && e.longitude < 55){
                  group2 = group2 + 1
              }
              else if (e.longitude > 55){
                  group3 = group3 + 1
              }
              cities.push(e.city)
          }
      })
  }
  var arr = [group1, group2, group3]
  let group = arr.reduce((maxIndex, currentValue, currentIndex, array) => {
      return currentValue > array[maxIndex] ? currentIndex : maxIndex;
  }, 0);

  var longitudes = [-100, 17, 108]

  return {
    lat: 23.016,
    lng: longitudes[group],
  }
}

function countUniqueElements(arr) {
  const elementCounts = {};

  arr.forEach((element) => {
      elementCounts[element] = (elementCounts[element] || 0) + 1;
  });

  return Object.keys(elementCounts);
}


const getUniqueCities = (allPosts) => {
  var cities = allPosts.map(e => e.city)
  var uniqueCities = countUniqueElements(cities)
  return uniqueCities
}

const getPostsShownZoomOut = (allPosts, uniqueCities) => {
  // gets all the posts to display when zoomed out.
  // if some posts are in no city, we still display them.
  // we add to the post diplay the attribute n_posts_city to display in the bubble.

  if (uniqueCities.length>0){
      var postsShownVar = []
      uniqueCities.forEach(c => {
          if (c != 1000 && c != -1){
              var postsCity = allPosts.filter(e => e.city == parseInt(c))
              if (postsCity.length>0){
                  postsCity.sort((a, b) => b.n_likes + b.n_real_likes - a.n_likes - a.n_real_likes);
              }
              postsCity[0].n_posts_city = postsCity.length
              postsShownVar.push(postsCity[0])
          }
      })
      // add the posts that are in none of the cities
      var postsNoDefinedCity = allPosts.filter(e => e.city == -1)
      postsShownVar = postsShownVar.concat(postsNoDefinedCity)
      return postsShownVar
  }
  else{
      var allPosts2 = [...allPosts]
      var allPosts2Len = allPosts2.sort((a, b) => b.n_likes + b.n_real_likes - a.n_likes - a.n_real_likes);
      return [allPosts2[0]]
  }
}

const addFlags = (postsShownVar, cities) => {
  var postShownVar2 = []
  if (postsShownVar && postsShownVar.length > 0 && cities && cities.length > 0){
      postsShownVar.forEach(p => {
          var city = cities.filter (cc => cc.id == p.city)
          if (city.length>0){
              p.name = city[0].name
              p.flag = city[0].flag
              postShownVar2.push(p)
          }
      })
  }
  return postShownVar2
}

function findExtremePoints(points) {
  return points.reduce((extremes, currentPoint) => {
      // Update most west point
      if (currentPoint.longitude < extremes.west.longitude) {
          extremes.west = currentPoint;
      }
      // Update most east point
      if (currentPoint.longitude > extremes.east.longitude) {
          extremes.east = currentPoint;
      }
      // Update most north point
      if (currentPoint.latitude > extremes.north.latitude) {
          extremes.north = currentPoint;
      }
      // Update most south point
      if (currentPoint.latitude < extremes.south.latitude) {
          extremes.south = currentPoint;
      }
      return extremes;
  }, {
      west: points[0],
      east: points[0],
      north: points[0],
      south: points[0]
  });
}

const  zoomThreshold = 5
const  zoomInValue = 12

const Map = (props) => {

  const [allPosts, setAllPosts] = React.useState(null)
  const [postsShown, setPostsShown] = React.useState([])
  const [region, setRegion] = React.useState(null)
  const [mapReady, setMapReady] = React.useState(false)
  const [postsShownWithFlags, setPostsShownWithFlags] = React.useState(null)
  const [isZoomedOut, setIsZoomedOut] = React.useState(true)
  const [showModal, setShowModal] = React.useState(false)
  const [currentMediaUrl, setCurrentMediaUrl] = React.useState(null)
  const [currentMediaType, setCurrentMediaType] = React.useState(null)
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
  });

  const mapRef = useRef();

  // On map load, store the map instance in a ref
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  
  React.useEffect(() => {

    if (props.username){
      GetUserPosts(props.username).then(res => {
        //console.log('TEST posts = ' + JSON.stringify(res.data))
        
        if (res.data && res.data.posts && res.data.posts.length>0 && res.data.cities && res.data.cities.length>0){

          setAllPosts (res.data)

          var pinPosition = getInitCoordinates([...res.data.posts])
          setRegion(pinPosition)

          var uniqueCitiesVar = getUniqueCities([...res.data.posts])
          var postsShownVar = getPostsShownZoomOut([...res.data.posts], uniqueCitiesVar)
          setPostsShown(postsShownVar)

          
          var postsShownVarWithFlags = addFlags(postsShownVar, res.data.cities)
          setPostsShownWithFlags(postsShownVarWithFlags) // will not be changed
          
          
          //setPostsShown(res.data)
          setMapReady(true)
        }
      })
      .catch(err => console.log(err))
    }
  }, [props.username])

  const onZoomChanged = React.useCallback(() => {
    if (mapRef.current && allPosts) {
      const currentZoom = mapRef.current.getZoom();
      if (currentZoom > zoomThreshold && JSON.stringify(postsShown) != JSON.stringify(allPosts.posts)) {
          setIsZoomedOut(false)
          setPostsShown([...allPosts.posts])
      } else if (currentZoom <= zoomThreshold && JSON.stringify(postsShown) != JSON.stringify(allPosts.posts)){
          setIsZoomedOut(true)
          var uniqueCitiesVar = getUniqueCities([...allPosts.posts])
          var postsShownVar = getPostsShownZoomOut([...allPosts.posts], uniqueCitiesVar)
          setPostsShown(postsShownVar)
      }
    }
  }, []);
  

  // Function to move the map to a specific location (smoothly)
  
  const moveToLocation = (newLocation, zoom) => {
    // Move smoothly to new location
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
      mapRef.current.setZoom(zoom);
    }
    setRegion(newLocation); // Update center state (optional)
  };
  


  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  if (!mapReady) return <div>wrong username...</div>;

  const CircularMarker = (props) => {
    return (
      <div 
        style={{
          position: 'relative',
          width: '70px',
          height: '70px',
          borderRadius: '50%',
          border: '1px solid white',
          boxShadow: '0 0 5px rgba(0,0,0,0.5)',
          zIndex: 1000
        }}
      >
        <div 
          style={{
            width: '70px',
            height: '70px',
            borderRadius: '50%',
            overflow: 'hidden'
          }}
        >
            <img src={props.imageUrl} alt="marker" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: 'block',
            }} />
        </div>
        
        {props.number > 0 && props.isZoomedOut && <div 
          className="bubble"
          style={{
            position: 'absolute',
            top: '-5px',
            right: '-5px',
            width: '22px',
            height: '22px',
            backgroundColor: 'rgb(162, 192, 244)',
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '11px',
            fontWeight: 'bold',
            zIndex: props.number.toString()
          }}>
            +{props.number}
          </div>}
      </div>
    );
  };

  return (
    <div className="map">

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={2} // Initial zoom level
        center={region} // Map center based on state
        onLoad={onMapLoad} // Store map instance when loaded
        onZoomChanged={onZoomChanged}
        options={{
          mapTypeId: 'terrain',
          minZoom: 2,
          mapTypeControl: false, // Disable the Map/Satellite control
          streetViewControl: false, // Disable Street View pegman
          zoomControl: false, // Disable zoom control buttons
          fullscreenControl: false, // (Optional) Disable fullscreen control
          gestureHandling: 'greedy',  // to have single touch scrolling
          styles: [
            {
              featureType: "water",
              stylers: [
                { hue: "#00bfff" },
                { saturation: 100 },
                { lightness: -20 }
              ]
            }
          ]
        }}
      >
        {postsShown && postsShown.length>0 && postsShown.map(p => {
            return(
              <OverlayViewF
                key={p.id}
                position={{ lat: p.latitude, lng: p.longitude }}  // Position of the marker
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}  // Pane for interaction
              >
                <div
                  onClick={() => {
                    if (p.city > -1 && p.n_posts_city > 0 && isZoomedOut){
                      var postsCity = [...allPosts.posts].filter(e => e.city == p.city)
                      var extremePoints = findExtremePoints(postsCity)
                      var latitudeVar = (extremePoints.north.latitude + extremePoints.south.latitude) / 2
                      var longitudeVar = (extremePoints.west.longitude + extremePoints.east.longitude) / 2
                      const regionVar = {
                          lat: latitudeVar,   
                          lng: longitudeVar
                      };
                      moveToLocation(regionVar, zoomInValue)
                      setPostsShown(postsCity)
                    }
                    else {
                      setShowModal(true)
                      
                      if (p.type == "webp"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.webp`)
                      }
                      else if (p.type == "mp4"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.mp4`)
                      }
                      setCurrentMediaType(p.type)
                    }
                  }}
                  style={{ cursor: 'pointer' }}  // Ensure cursor indicates interactivity
                >
                  <CircularMarker 
                    imageUrl={`${serverIp}/images/${p.id}_lowqual.webp`}  // Custom image URL
                    number={p.n_posts_city}  // Number in the bubble
                    isZoomedOut={isZoomedOut}
                  />
                </div>
              </OverlayViewF>
            )
        })}
      </GoogleMap>

      <div className="title">
          <div 
            className="title2"
            onClick={() => {
              var regionVar = getInitCoordinates([...allPosts.posts])
              //animateToRegion(regionVar)
              moveToLocation(regionVar, 2)

              var uniqueCitiesVar = getUniqueCities([...allPosts.posts])
              var postsShownVar = getPostsShownZoomOut([...allPosts.posts], uniqueCitiesVar)
              setPostsShown(postsShownVar)
              
            }}
          >
              <p>{props.username}'s map</p>
          </div>
      </div>

      <div className="linkapp">
          <div 
            className="linkapp2"
            onClick={() => { 
                window.location.href = 'https://apps.apple.com/us/app/mnti/id6468454177'
            }}
          >
              <p>create your own map</p>
              {false && <img src={require("../public/icon_apple.png")}  style={{width: '20px', height: '20px', objectFit: 'cover', display: 'block',}}></img>}
          </div>
      </div>

      {postsShownWithFlags && postsShownWithFlags.length > 0 && <SelectCities
        cities={postsShownWithFlags}
        onClick={(city) => {

          var postsCity = [...allPosts.posts].filter(e => e.city == city)

          var extremePoints = findExtremePoints(postsCity)

          var latitudeVar = (extremePoints.north.latitude + extremePoints.south.latitude) / 2
          var longitudeVar = (extremePoints.west.longitude + extremePoints.east.longitude) / 2

          const regionVar = {
              lat: latitudeVar,   
              lng: longitudeVar
          };
    
          moveToLocation(regionVar, zoomInValue)
          setPostsShown(postsCity)
        }}
      />}

      {showModal && (
        <MediaModal 
          mediaUrl={currentMediaUrl} 
          mediaType={currentMediaType}
          onClose={() => {
            setShowModal(false)
          }} 
        />
      )}
    </div>
  );
}

export default Map;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-selectcities{
    width: 100%;
    position: absolute;
    bottom: 100px;
    left: 0px;
}

.scroll-container {
    top: 0px;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line break between items */
    gap: 10px; /* Add some space between items */
    scrollbar-width: none; /* Firefox: Hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge: Hide scrollbar */
    height: 45px;
}

.scroll-item {
    min-width: 100px; /* Set a fixed or minimum width for each item */
    height: 35px;
    background-color: rgba(162, 192, 244, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    font-size: 16px;
    color: rgba(0,0,0,0.7);
    font-family: 'font1', sans-serif;
}

.flag{
    font-size: 22px;
    margin-right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectCities.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,QAAQ;IACR,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,gBAAgB,EAAE,gCAAgC;IAClD,mBAAmB,EAAE,qCAAqC;IAC1D,SAAS,EAAE,iCAAiC;IAC5C,qBAAqB,EAAE,4BAA4B;IACnD,wBAAwB,EAAE,gCAAgC;IAC1D,YAAY;AAChB;;AAEA;IACI,gBAAgB,EAAE,+CAA+C;IACjE,YAAY;IACZ,wCAAwC;IACxC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,wCAAwC;IACxC,eAAe;IACf,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".main-selectcities{\n    width: 100%;\n    position: absolute;\n    bottom: 100px;\n    left: 0px;\n}\n\n.scroll-container {\n    top: 0px;\n    display: flex;\n    justify-content: flex-start;\n    padding-left: 10px;\n    align-items: center;\n    width: 100%;\n    overflow-x: auto; /* Enable horizontal scrolling */\n    white-space: nowrap; /* Prevent line break between items */\n    gap: 10px; /* Add some space between items */\n    scrollbar-width: none; /* Firefox: Hide scrollbar */\n    -ms-overflow-style: none; /* IE and Edge: Hide scrollbar */\n    height: 45px;\n}\n\n.scroll-item {\n    min-width: 100px; /* Set a fixed or minimum width for each item */\n    height: 35px;\n    background-color: rgba(162, 192, 244, 1);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    padding-left: 10px;\n    padding-right: 10px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);\n    font-size: 16px;\n    color: rgba(0,0,0,0.7);\n    font-family: 'font1', sans-serif;\n}\n\n.flag{\n    font-size: 22px;\n    margin-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

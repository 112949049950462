import React from 'react';
import './MediaModal.css'; 

const MediaModal = (props) => {
  return (
    <div className="modal-overlay" onClick={props.onClose}>
      <div className="modal-content">
        {props.mediaType == "webp" && <img src={props.mediaUrl} alt="Full-screen" className="modal-image" />}
        
        {props.mediaType == "mp4" && <video width="100%" controls autoPlay muted>
            <source src={props.mediaUrl} type="video/mp4" />
        </video>}

      </div>
    </div>
  );
};

export default MediaModal;
